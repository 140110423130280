import React, { FC } from "react";
import { ISVG } from "types/ISVG";

const FoundPaymentIllustrationSVG: FC<ISVG> = ({width, height}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 200 200"
    >
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.661"
        d="M146.851 88.974c-.713-.32-1.602-.232-2.475.336l-7.347 4.775 45.889 20.669 7.347-4.774c.873-.569 1.77-.657 2.475-.337l-45.889-20.67z"
      ></path>
      <path
        fill="#000"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.661"
        d="M70.792 197.111l-1.122-4.903-45.889-20.669 1.122 4.903c.2.881.689 1.498 1.33 1.787l45.889 20.669c-.633-.289-1.122-.897-1.33-1.787zM78.474 178.661l-1.122-4.903-45.889-20.669 1.122 4.903c.432 1.866-.577 4.286-2.235 5.367l45.889 20.67c1.658-1.082 2.667-3.501 2.235-5.368z"
      ></path>
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.661"
        d="M30.343 163.367l-4.334 2.812c-1.659 1.082-2.66 3.493-2.236 5.368l45.89 20.669c-.433-1.867.568-4.286 2.234-5.368l4.335-2.812-45.89-20.669zM133.793 83.991c-.705-.32-1.594-.232-2.468.337l-4.334 2.82-88.966 57.777-4.334 2.812c-1.658 1.082-2.66 3.493-2.235 5.368l45.889 20.669c-.433-1.867.569-4.286 2.235-5.368l4.334-2.812 93.309-60.597c.873-.569 1.762-.657 2.467-.337l-45.889-20.669h-.008z"
      ></path>
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.661"
        d="M190.272 109.972c1.658-1.082 3.373-.425 3.805 1.442l1.122 4.903c.432 1.866-.577 4.286-2.235 5.367L74.596 198.553c-1.659 1.082-3.365.425-3.797-1.45l-1.122-4.903c-.433-1.867.569-4.286 2.235-5.368l4.334-2.812c1.659-1.081 2.668-3.501 2.235-5.367l-1.121-4.903c-.433-1.867.569-4.286 2.235-5.368l4.334-2.812 88.966-57.777 4.334-2.82c1.659-1.074 3.365-.425 3.798 1.45l1.217 5.303c.377 1.65 1.851 2.243 3.269 1.322l4.767-3.092-.008.016z"
      ></path>
      <path
        fill="#000"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.661"
        d="M33.691 147.729c-1.658 1.082-2.66 3.493-2.235 5.368L32.578 158c.432 1.866-.577 4.286-2.235 5.367l-4.334 2.812c-1.659 1.082-2.66 3.493-2.236 5.368l1.122 4.903c.2.881.689 1.498 1.33 1.786l6.513 2.933 2.548-6.289 15.101-2.476 9.822-8.892 18.803-3.654-28.433-23.096-12.57 8.163-4.334 2.812.016-.008z"
      ></path>
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.661"
        d="M148.51 47.435l13.443 58.563-20.734 13.467-13.443-58.563 20.734-13.467z"
      ></path>
      <path
        fill="#000"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.661"
        d="M141.219 119.465l-37.789-17.024-13.452-58.563 37.798 17.024 13.443 58.563z"
      ></path>
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.661"
        d="M127.776 60.902L89.979 43.878l20.733-13.46 37.797 17.017-20.733 13.467zM117.409 67.632l13.443 58.563-20.733 13.467-13.451-58.563 20.741-13.467z"
      ></path>
      <path
        fill="#000"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.661"
        d="M110.119 139.662l-37.798-17.024-13.443-58.555 37.79 17.016 13.451 58.563z"
      ></path>
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.661"
        d="M96.668 81.099l-37.79-17.016L79.61 50.616l37.798 17.016-20.742 13.467zM86.302 87.836l13.45 58.555-20.733 13.467-13.443-58.563 20.726-13.46z"
      ></path>
      <path
        fill="#000"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.661"
        d="M79.019 159.858L41.22 142.834 27.778 84.271l37.798 17.024 13.443 58.563z"
      ></path>
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.661"
        d="M65.576 101.295L27.778 84.271l20.734-13.459 37.79 17.024-20.726 13.459zM128.025 6.978c-.705-.32-1.594-.232-2.475.344l-4.334 2.812c-.874.57-1.763.657-2.468.337l45.889 20.67c.705.32 1.594.231 2.468-.337l4.334-2.812c.881-.57 1.77-.657 2.475-.345l-45.889-20.67z"
      ></path>
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.661"
        d="M114.974 2.003c-.705-.32-1.594-.233-2.468.336l-7.354 4.775-7.354 4.775c-.874.569-1.763.657-2.468.336l45.889 20.67c.705.32 1.594.232 2.468-.337l7.354-4.775 7.354-4.774c.874-.57 1.763-.657 2.468-.337l-45.889-20.67z"
      ></path>
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.661"
        d="M91.549 3.757c-.705-.32-1.594-.232-2.468.337l-7.354 4.774-7.346 4.775c-.874.569-1.763.657-2.468.337l45.889 20.669c.705.32 1.594.232 2.468-.337l7.346-4.774 7.354-4.775c.874-.569 1.763-.657 2.468-.337L91.548 3.757z"
      ></path>
      <path
        fill="#000"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.661"
        d="M95.74 46.322l-1.122-4.895-45.89-20.67 1.122 4.895c.433 1.875-.576 4.294-2.235 5.368l45.89 20.67c1.658-1.074 2.659-3.494 2.234-5.368z"
      ></path>
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.661"
        d="M68.132 5.512c-.713-.32-1.603-.233-2.476.336l-7.346 4.775-7.355 4.775c-1.658 1.081-2.667 3.484-2.235 5.367l45.89 20.67c-.433-1.875.568-4.287 2.234-5.368l7.355-4.775 7.346-4.775c.874-.569 1.771-.657 2.476-.336L68.131 5.51z"
      ></path>
      <path
        fill="#000"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.661"
        d="M77.689 71.501l-1.122-4.895-45.889-20.669 1.122 4.895c.432 1.875-.57 4.294-2.236 5.367l45.89 20.67c1.658-1.082 2.66-3.493 2.235-5.368z"
      ></path>
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.661"
        d="M47.606 31.02l-7.354 4.775-7.346 4.774c-1.659 1.082-2.668 3.493-2.235 5.368l45.889 20.67c-.433-1.875.576-4.287 2.235-5.368l7.346-4.775 7.355-4.775-45.89-20.669z"
      ></path>
      <path
        fill="#000"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.661"
        d="M51.965 115.123l-1.122-4.895-45.889-20.67 1.122 4.895c.208.89.689 1.507 1.33 1.787l45.889 20.669c-.633-.288-1.122-.897-1.33-1.786zM59.648 96.673l-1.122-4.895-45.889-20.67 1.122 4.895c.432 1.875-.577 4.286-2.236 5.368l45.89 20.669c1.658-1.081 2.667-3.493 2.235-5.367z"
      ></path>
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.661"
        d="M11.515 81.379L7.181 84.19c-1.658 1.081-2.66 3.493-2.235 5.367l45.89 20.67c-.433-1.875.568-4.294 2.234-5.368l4.334-2.812-45.889-20.67zM68.107 81.643l7.347-4.775L29.564 56.2l-7.346 4.775-7.354 4.775c-1.659 1.081-2.668 3.485-2.236 5.367l45.89 20.67c-.433-1.875.688-4.359 2.235-5.368l7.354-4.775z"
      ></path>
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.661"
        d="M171.447 27.983c1.666-1.081 3.372-.432 3.805 1.45l1.122 4.895c.432 1.883-.569 4.286-2.236 5.368L55.771 116.565c-1.667 1.081-3.365.432-3.798-1.45l-1.121-4.895c-.433-1.875.569-4.294 2.235-5.368l4.334-2.812c1.658-1.081 2.668-3.493 2.235-5.367l-1.121-4.895c-.433-1.875.568-4.286 2.235-5.368l7.354-4.775 7.347-4.774c1.658-1.082 2.66-3.493 2.235-5.368l-1.122-4.895c-.432-1.875.577-4.286 2.235-5.367l7.347-4.775 7.354-4.775c1.659-1.074 2.66-3.493 2.235-5.368l-1.121-4.895c-.433-1.874.569-4.286 2.235-5.367l7.354-4.775 7.347-4.775c1.658-1.081 3.373-.432 3.805 1.442l1.122 4.895c.432 1.875 2.139 2.532 3.797 1.458l7.347-4.774 7.354-4.775c1.658-1.082 3.365-.425 3.797 1.45l1.122 4.895c.433 1.875 2.147 2.531 3.797 1.458l7.355-4.775L158.42 23c1.659-1.073 3.365-.424 3.798 1.45l1.121 4.895c.433 1.875 2.139 2.524 3.798 1.45l4.334-2.812h-.024zM124.059 153.713c.577 2.508 2.812 3.413 4.967 2.011l13.699-8.9c2.155-1.402 3.453-4.599 2.876-7.107-.576-2.507-2.812-3.412-4.967-2.01l-13.699 8.9c-2.155 1.402-3.453 4.599-2.876 7.106z"
      ></path>
    </svg>
  );
}

export default FoundPaymentIllustrationSVG;
