import styles from "./CancelPayment.module.scss";
import { useTranslation } from "react-i18next";
import Layout from "layout/Layout";
import Hash from "components/Hash/Hash";
import CancelIllustrationSVG from "svg/illustrations/CancelIllustrationSVG";
import { CancelPaymentSVG } from "svg/icons/CancelPaymentSVG";
import useUrlData from "store/DataStore";
import useDeviceStore from "store/DeviceStore";
import { getDeviceInfo } from "common/commonFunctions";
import { useEffect } from "react";

const CancelPayment = () => {
  const { t } = useTranslation();
  const allData = useUrlData((state) => state.data);
  const amount = allData.amount
  const { setDevice } = useDeviceStore();
  
  useEffect(() => {
    getDeviceInfo(setDevice);
  }, []);
  
  return (
    <Layout
      headerText={t('')}
      headerStep={t("3.Платеж отменен.")}
      currentStep={3}
    >
      <div className={styles.wrapper}>
        <div className={styles.up}>
          <Hash />

          <CancelPaymentSVG width="42" height="42" />
        </div>

        <div className={styles.content}>
          <CancelIllustrationSVG width="175" height="200" />
          <p>{t("Вы отменили платеж {{amount}} ₽", { amount })}</p>
          <span>{t("Новую заявку на оплату вы можете создать на сайте.")}</span>
        </div>
      </div>
    </Layout>
  );
};

export default CancelPayment;
