import styles from './Modal.module.scss'
import {FC, useMemo, useState} from 'react'
import { ModalHeader } from './ModalHeader'
import { ModalAttention } from './ModalContent/ModalAttention'
import { ModalHeaderAttention } from './ModalHeaderContent/ModalHeaderAttention'
import { ModalCancel } from './ModalContent/ModalCancel'
import { ModalHeaderCancel } from './ModalHeaderContent/ModalHeaderCancel'

type ModalVariant = "edit" | "attention" | "cancel" | "accept" | "undefined";

interface Props{
  children?: React.ReactNode,
  type?: ModalVariant,
  goTo?: () => void
  onClose?: () => void
}

export const Modal: FC<Props> = ({ children, type, goTo, onClose }) =>  {
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);

    setTimeout(() => {
      if (onClose) onClose();
    }, 150);
  };

  const component = useMemo(() => {
    switch (type) {
      case 'attention':
        return <ModalAttention goTo={goTo} onClose={handleClose} />;
      case 'cancel':
        return <ModalCancel goTo={goTo} onClose={handleClose}/>;
      default:
        return null;
    }
  }, [type, goTo, onClose]);
  
  const headerComponent = useMemo(() => {
    switch (type) {
      case 'attention':
        return <ModalHeaderAttention />;
      case 'cancel':
        return <ModalHeaderCancel />;
      default:
        return null;
    }
  }, [type]);
  
  return (
    <>
      <div className={`${styles.modalWrapper} ${isClosing ? styles.fadeOut : ''}`}>
          <div className={styles.modalContent}>
            <ModalHeader>
              {headerComponent}
            </ModalHeader>
            <div className={styles.modalBody}>
              {component}
            </div>
          </div>
        </div>
    </>
  )
}
