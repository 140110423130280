import styles from "./SuccessPayment.module.scss";
import { useTranslation } from "react-i18next";
import Layout from "layout/Layout";
import Hash from "components/Hash/Hash";
import DoneIllustrationSVG from "svg/illustrations/DoneIllustrationSVG";
import { DoneSVG } from "svg/icons/DoneSVG";
import { useEffect } from "react";
import useDeviceStore from "store/DeviceStore";
import { getDeviceInfo } from "common/commonFunctions";
import useUrlData from "store/DataStore";
import { usePaymentData } from "hooks/usePaymentData";
import useTokenStore from "store/TokenStore";
import useErrorStore from "store/errorStore";
import { useNavigate } from "react-router-dom";
import { Loader } from "UI/Loader/Loader";

const SuccessPayment = () => {
  const { t } = useTranslation();
  const { setDevice } = useDeviceStore();
  const allData = useUrlData((state) => state.data);
  const { getPaymentData, actionExecuting} = usePaymentData();
  const { setToken } = useTokenStore();
  const { networkErr } = useErrorStore((state) => state);
  const navigate = useNavigate();

  useEffect(() => {
    allData.status_name === 'COMPLETED' && navigate('/success');
    allData.status_name === 'CANCELED' && navigate('/canceled',{ state: 'CANCELED' });
      if(networkErr){
        navigate('/canceled', {state: 'NETWORKERR'});
      }

    if (allData.payment_id === '') {
      getPaymentData(sessionStorage.getItem('p_token')!);
      setToken(sessionStorage.getItem('p_token')!)
      getDeviceInfo(setDevice);
      
    } 
    else if(sessionStorage.getItem('p_token') === null) {
      navigate('/canceled',{ state: 'NOPAYMENT' });
    }
  }, [allData,networkErr]);

  return (
    <Layout
      headerText={''}
      headerStep={t("3. Подтверждение.")}
      currentStep={3}
    >
      {!actionExecuting ?
        <div className={styles.wrapper}>
        <div className={styles.up}>
          <Hash />

          <DoneSVG width="42" height="42" />
        </div>

        <div className={styles.content}>
          <DoneIllustrationSVG width="175" height="200" />

          <p>{t("Оплата прошла успешно")}</p>
        </div>
      </div>
      :
      <div className="loaderBlock">
        <Loader />
      </div>
      }
    </Layout>
  );
};

export default SuccessPayment;
