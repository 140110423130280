import React, { ButtonHTMLAttributes } from "react";
import styles from "./Button.module.scss";
import cn from "classnames";

type ButtonVariant = "primary" | "yellow" | "red" | "gray" | "outline" | "disabled" | "link";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  className?: string;
}

export const Button: React.FC<ButtonProps> = ({
  variant = "primary",
  children,
  className,
  ...props
}) => {
  return (
    <button className={cn(styles[variant], className)} disabled={variant === "disabled"} {...props}>
      {children}
    </button>
  );
};
