import create from "zustand";

interface NotifyStore {
  isCopied: boolean;
  message: string;
  setCopied: (copied: boolean, message: string) => void;
}

const useNotifyStore = create<NotifyStore>((set) => ({
  isCopied: false,
  message: "",
  setCopied: (isCopied, message) => set({ isCopied, message }),
}));

export default useNotifyStore;
