import React, { FC } from "react";
import { ISVG } from "types/ISVG";

export const CloseModalSVG: FC<ISVG> = ({width, height}) => {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_54_21532)">
    <path d="M-1.79217e-06 0L0 41L41 41L41 -1.79217e-06L-1.79217e-06 0Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.1961 12.5183C15.3383 11.6606 13.9476 11.6606 13.0899 12.5183C12.2321 13.3761 12.2321 14.7668 13.0899 15.6245L17.3939 19.9286L13.0899 24.2326C12.2321 25.0904 12.2321 26.4811 13.0899 27.3388C13.9476 28.1966 15.3383 28.1966 16.1961 27.3388L20.5001 23.0348L24.8041 27.3388C25.6619 28.1966 27.0526 28.1966 27.9104 27.3388C28.7681 26.4811 28.7681 25.0904 27.9104 24.2326L23.6063 19.9286L27.9104 15.6245C28.7681 14.7668 28.7681 13.3761 27.9104 12.5183C27.0526 11.6606 25.6619 11.6606 24.8041 12.5183L20.5001 16.8224L16.1961 12.5183Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_54_21532">
    <rect y="41" width="41" height="41" rx="14.6429" transform="rotate(-90 0 41)" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  );
}

