import styles from "./QRPay.module.scss";
import { useEffect, useState } from "react";
import { Button } from "UI/Button/Button";
import Layout from "layout/Layout";
import Hash from "components/Hash/Hash";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Modal } from "UI/Modal/Modal";
import SbpPaySVG from "svg/payMethods/SbpPaySVG";
import SberPayLogo from "svg/payMethods/SberPayLogo";
import { QRCodeSVG } from "qrcode.react";
import TimerComponent from "components/Timer/Timer";
import useTimerData from "store/TimerStore";
import useUrlData from "store/DataStore";
import { usePaymentData } from "hooks/usePaymentData";
import { Loader } from "UI/Loader/Loader";
import useTokenStore from "store/TokenStore";
import { getDeviceInfo } from "common/commonFunctions";
import useDeviceStore from "store/DeviceStore";
import useErrorStore from "store/errorStore";
import SbpBtnPay from "components/SbpBtnPay/SbpBtnPay";

const QRPay = () => {
  const { t } = useTranslation();
  const allData = useUrlData((state) => state.data);
  const timeLimit = useTimerData((state) => state.end);
  const [isPay, setIsPay] = useState(false);
  const navigate = useNavigate();
  const [onCancel, setOnCancel] = useState(false);
  const { getPaymentData, actionExecuting} = usePaymentData();
  const { setToken } = useTokenStore();
  const { setDevice } = useDeviceStore();
  const { networkErr } = useErrorStore((state) => state);

  useEffect(() => {
    if(timeLimit){
      navigate('/canceled',{state: 'TIMEISUP'})
    }
  },[navigate, timeLimit])

  useEffect(() => {
    allData.status_name === 'COMPLETED' && navigate('/success');
    allData.status_name === 'CANCELED' && navigate('/canceled',{ state: 'CANCELED' });
      if(networkErr){
        navigate('/canceled', {state: 'NETWORKERR'});
      }
    if (allData.payment_id === '') {
      getPaymentData(sessionStorage.getItem('p_token')!);
      setToken(sessionStorage.getItem('p_token')!)
      getDeviceInfo(setDevice);
    } 
    else if(sessionStorage.getItem('p_token') === null) {
      navigate('/canceled',{ state: 'NOPAYMENT' });
    }
  }, [allData,networkErr]);

  return (
    <Layout
      headerText={t("Скопируйте реквизиты получателя")}
      headerStep={t("2. Оплата.")}
      currentStep={2}
    >
      {!actionExecuting ? 
        <div className={styles.wrapper}>
          <div className={styles.up}>
            <Hash />
            <TimerComponent />
          </div>

          <div className={styles.content}>
            <div className={styles.info}>
              <div className={styles.value}>
                <p>{allData.amount} ₽</p>
                <SberPayLogo />
              </div>
              <div className={styles.desc}>
                {t("Отсканируйте QR-код с камеры вашего устройства")}
              </div>
            </div>
            
            <div className={styles.qrContainer}>
              <div className={styles.qrBg}>
                <QRCodeSVG size={130} value={`https://www.sberbank.com/sms/pbpn?requisiteNumber=[${allData.phone_number}]`} />
              </div>
            </div>

            <div className={styles.buttons}>
              <Button onClick={() => setIsPay(true)} variant="yellow">
                {t("Оплачено")}
              </Button>

              <div className={styles.sbpNavBtn} onClick={() => navigate("/payment")}>
                <SbpBtnPay />
              </div>              

              {allData.bank_card_number !== null && 
              <>
                <div className={styles.or}>
                  <div />
                  <span>{t("Или выберите другой способ оплаты")}</span>
                  <div />
                </div>
                <div onClick={() => navigate("/payment")} className={styles.payBtn}>
                <SbpPaySVG height="60" width="372" className={styles.paySvg}  />
                </div>
              </>
              }

              <Button variant="link" onClick={() => setOnCancel(true)}>
                {t("Отменить платеж")}
              </Button>
            </div>
          </div>
        </div>
        :
        <div className="loaderBlock">
          <Loader />
        </div>
      }
      

      {onCancel && (
        <Modal
          type="cancel"
          goTo={() => navigate("/cancel")}
          onClose={() => setOnCancel(false)}
        />
      )}

      {isPay && <Modal type="attention" goTo={() => navigate("/searchPay")} onClose={() => setIsPay(false)}/>}
    </Layout>
  );
};

export default QRPay;
