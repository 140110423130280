import axios from 'axios'
import { useState } from 'react'
import  { consts } from 'consts'
interface ICancel{
  status: string;
}

export const useCancelPayment = () : {
  cancelStatus: string,
  actionExecuting: boolean,
  getCancelStatus: Function,
} => {
  const [cancelStatus, setCancelStatus] = useState('')
  const [actionExecuting, setActionExecuting] = useState(false)
  const { BaseUrl } = consts()

  async function getCancelStatus(token: string) {
    setActionExecuting(true)
    try {
      const response:ICancel = await axios.post(
        `${BaseUrl}pay/api/v1/pay_form/cancel/${token}`,
      )
      setCancelStatus(response.status.toString())
    } catch (error) {
      setCancelStatus('error')
      console.log(error)
    } finally {
      setActionExecuting(false)
    }
  }
  

  return {
    cancelStatus,
    actionExecuting,
    getCancelStatus,
  }
}