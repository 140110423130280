import React from "react";

const CopySVG = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 3.13281H4C2.89543 3.13281 2 4.02824 2 5.13281V12.1328C2 13.2374 2.89543 14.1328 4 14.1328H14C15.1046 14.1328 16 13.2374 16 12.1328V5.13281C16 4.02824 15.1046 3.13281 14 3.13281Z"
        stroke="#BFBCC9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 8C20.1 8 21 8.9 21 10V17C21 18.1 20.1 19 19 19H9C7.9 19 7 18.1 7 17V16.6328"
        stroke="#BFBCC9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CopySVG;
