import styles from "./CancelRequest.module.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "layout/Layout";
import Hash from "components/Hash/Hash";
import ProcessingIllustrationSVG from "svg/illustrations/ProcessingIllustrationSVG";
import { Loader } from "UI/Loader/Loader";
import { Modal } from "UI/Modal/Modal";
import useDeviceStore from "store/DeviceStore";
import { getDeviceInfo } from "common/commonFunctions";
import { useCancelPayment } from "hooks/useCancelPayment";
import useUrlData from "store/DataStore";
import useTokenStore from "store/TokenStore";
import { useNavigate } from "react-router-dom";
import useErrorStore from "store/errorStore";

const CancelRequest = () => {
  const { t } = useTranslation();
  const [onAccept, setOnAccept] = useState(false);
  const allData = useUrlData((state) => state.data);
  const token = useTokenStore((state) => state.token);
  const navigate = useNavigate()
  const { setDevice } = useDeviceStore();
  const { getCancelStatus ,cancelStatus , actionExecuting } = useCancelPayment();
  const { setToken } = useTokenStore();
  const { networkErr } = useErrorStore((state) => state);

  useEffect(() => {
    allData.status_name === 'COMPLETED' && navigate('/success');
    allData.status_name === 'CANCELED' &&  navigate('/canceled',{ state: 'CANCELED' });
      if(networkErr){
        navigate('/canceled', {state: 'NETWORKERR'});
      }
    
    getCancelStatus(token);
    if (allData.payment_id === '') {
      setToken(sessionStorage.getItem('p_token')!)
      getCancelStatus(sessionStorage.getItem('p_token'));
      getDeviceInfo(setDevice);
    } 
    else if(sessionStorage.getItem('p_token') === null) {
      navigate('/canceled',{ state: 'NOPAYMENT' });
    }
  }, [allData,networkErr]);

  useEffect(() => {
    
    if(cancelStatus === '200'){
      navigate('/canceled',{ state: 'CANCELED' });
    }else if (cancelStatus === '404'){
      navigate('/canceled',{ state: 'NOPAYMENT' });
    }else if (cancelStatus === 'error'){
      navigate('/canceled');
    }
  },[navigate, cancelStatus])

  return (
    <Layout
      headerText={t("Загрузите скриншот оплаты")}
      headerStep={t("3. Подтверждение.")}
      currentStep={3}
    >
      {!actionExecuting ? 
        <div className={styles.wrapper}>
          <div className={styles.up}>
            <Hash />

            <Loader />
          </div>

          <div className={styles.content}>
            
            <ProcessingIllustrationSVG width="221" height="172"/>

            <p> {t("Ваш запрос об отмене платежа обрабатывается")}</p>
          </div>
        </div>
      :
        <div className="loaderBlock">
          <Loader />
        </div>
      }

      {onAccept && <Modal type="accept" onClose={() => setOnAccept(false)} />}
    </Layout>
  );
};

export default CancelRequest;
