import styles from "./searchpay.module.scss";
import { useEffect } from "react";
import Layout from "layout/Layout";
import Hash from "components/Hash/Hash";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useTimerData from "store/TimerStore";
import useUrlData from "store/DataStore";
import { Loader } from "UI/Loader/Loader";
import { usePaymentStatus } from "hooks/useCheckPayment";
import useTokenStore from "store/TokenStore";
import { usePaymentData } from "hooks/usePaymentData";
import useDeviceStore from "store/DeviceStore";
import { getDeviceInfo } from "common/commonFunctions";
import useErrorStore from "store/errorStore";

const SearchPay = () => {
  const { t } = useTranslation();
  const allData = useUrlData((state) => state.data);
  const timeLimit = useTimerData((state) => state.end);
  const token = useTokenStore((state) => state.token);
  const navigate = useNavigate();
  const { paymentStatus, getPaymentStatus } = usePaymentStatus();
  const { getPaymentData, actionExecuting} = usePaymentData();
  const { setToken } = useTokenStore();
  const { setDevice } = useDeviceStore();
  const { networkErr } = useErrorStore((state) => state);

  useEffect(() => {
    paymentStatus === 'COMPLETED' && navigate('/success');
    paymentStatus === 'CANCELED' && navigate('/canceled',{ state: 'CANCELED' });
  },[navigate, paymentStatus])

  useEffect(() => {
    allData.status_name === 'COMPLETED' && navigate('/success');
    allData.status_name === 'CANCELED' && navigate('/canceled',{ state: 'CANCELED' });
    if(networkErr){
      alert('ошибка подключения') 
    }
  },[allData,networkErr])

  useEffect(() => {
    getPaymentStatus(token)
  },[token]);

  useEffect(() => {
    if(timeLimit){
      navigate('/canceled',{state: 'TIMEISUP'})
    }
  },[navigate, timeLimit])

  useEffect(() => {
    if (allData.payment_id === '') {
      getPaymentData(sessionStorage.getItem('p_token')!);
      setToken(sessionStorage.getItem('p_token')!)
      getDeviceInfo(setDevice);
    } 
    else if(sessionStorage.getItem('p_token') === null) {
      navigate('/canceled',{ state: 'NOPAYMENT' });
    }
  }, [allData]);

  return (
    <Layout
      headerText={''}
      headerStep={t("2. Оплата.")}
      currentStep={2}
    >
      {!actionExecuting ?
      <div className={styles.wrapper}>
        <div className={styles.up}>
          <Hash />
          <Loader />
        </div>

        <div className={styles.content}>

          <div className={styles.qrContainer}>
            <span className={styles.searchTitle}>
              {t("Ваш платеж в обработке")}
            </span>
            <span className={styles.searchText}>
              {t("Это может занять несколько минут")}
            </span>
          </div>
        </div>
      </div>
      :
      <div className="loaderBlock">
        <Loader />
      </div>}
    </Layout>
  );
};

export default SearchPay;