import React from "react";

function JCB() {
  return (
    <svg
      width="23"
      height="18"
      viewBox="0 0 23 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7745 0C17.2029 0 15.9142 1.26148 15.9142 2.83444V5.7779H19.9533C20.0476 5.7779 20.1576 5.7779 20.2362 5.79347C21.1477 5.84019 21.8235 6.30741 21.8235 7.11725C21.8235 7.75577 21.3677 8.30086 20.519 8.40987V8.44102C21.4463 8.50332 22.1535 9.01725 22.1535 9.81152C22.1535 10.6681 21.3677 11.2287 20.3304 11.2287H15.8984V16.9911H20.0947C21.6663 16.9911 22.9551 15.7296 22.9551 14.1566V0H18.7745Z"
        fill="#9C99A6"
      />
      <path
        d="M17.314 10.3275H18.9642C18.9878 10.3275 19.0271 10.3236 19.0664 10.3197C19.1057 10.3158 19.145 10.3119 19.1685 10.3119C19.4829 10.2496 19.75 9.96927 19.75 9.57992C19.75 9.20615 19.4829 8.92582 19.1685 8.84795C19.1214 8.83238 19.0271 8.83238 18.9642 8.83238H17.314V10.3275Z"
        fill="#9C99A6"
      />
      <path
        d="M18.9642 6.6354C19.2785 6.68212 19.5457 6.9313 19.5457 7.30507C19.5457 7.67884 19.2785 7.92802 18.9642 7.97475C18.9485 7.99032 18.8542 7.99032 18.8071 7.99032H17.314V6.61982H18.8071C18.833 6.61982 18.8685 6.62454 18.9004 6.62878C18.9264 6.63225 18.9501 6.6354 18.9642 6.6354Z"
        fill="#9C99A6"
      />
      <path
        d="M2.96594 0.00375366C1.3943 0.00375366 0.10556 1.26523 0.10556 2.83819V9.83085C0.907094 10.2202 1.74006 10.4694 2.57303 10.4694C3.56316 10.4694 4.09751 9.87757 4.09751 9.06773V5.76608H6.54926V9.05216C6.54926 10.3292 5.74773 11.3727 3.0288 11.3727C1.37858 11.3727 0.0898438 11.0145 0.0898438 11.0145V16.9792H4.28611C5.85774 16.9792 7.14649 15.7178 7.14649 14.1448V0.00375366H2.96594Z"
        fill="#9C99A6"
      />
      <path
        d="M8.00928 2.83819C8.00928 1.26524 9.29802 0.00375366 10.8697 0.00375366H15.0659V14.176C15.0659 15.7489 13.7772 17.0104 12.2055 17.0104H8.00928V10.4694C8.73223 11.0768 9.98954 11.4661 12.0169 11.3727C13.1171 11.3259 14.2644 11.03 14.2644 11.03V9.83085C13.6829 10.1112 12.9914 10.3915 12.0955 10.4538C10.5553 10.5784 9.62806 9.83085 9.62806 8.50707C9.62806 7.19887 10.5553 6.45132 12.0955 6.56034C12.9914 6.62264 13.6829 6.88739 14.2644 7.1833V5.98411C14.2644 5.98411 13.1014 5.68821 12.0169 5.64149C9.98954 5.54804 8.73223 5.93739 8.00928 6.54477V2.83819Z"
        fill="#9C99A6"
      />
    </svg>
  );
}

export default JCB;
