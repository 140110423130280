import axios from 'axios';
import { consts } from 'consts';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useUrlData, { IUrlDataInit } from 'store/DataStore';
import useErrorStore from 'store/errorStore';

interface IError{
  code?: string;
  response?: {
    status: number;
  }
}

export const usePaymentData = () => {
  const { BaseUrl } = consts();
  const [paymentData, setPaymentData] = useState<IUrlDataInit['data'] | null>(null);
  const [actionExecuting, setActionExecuting] = useState(false);
  const setData = useUrlData((state) => state.setData);
  const { setNetworkErr } = useErrorStore((state) => state);
  const navigate = useNavigate();

  const maxRetries = 3;
  const retryDelay = 2000;

  async function getPaymentData(token: string) {
    setActionExecuting(true);
  
    let attempt = 0;
    let success = false;
  
    while (attempt < maxRetries && !success) {
      try {
        const response = await axios.get(`${BaseUrl}pay/api/v1/pay_form/${token}`);
        const newPaymentData = response.data;
        setPaymentData(newPaymentData);
        setData(newPaymentData);
        success = true;
      } catch (error: unknown) {
        if (typeof error === 'object' && error !== null && 'response' in error) {
          const err = error as IError;
  
          if (err.response?.status === 404) {
            navigate('/canceled', { state: 'NOPAYMENT' });
            setActionExecuting(false);
            break;
          }
        }

        if (typeof error === 'object' && error !== null && 'code' in error) {
          const err = error as IError;
  
          if (err.code === 'ERR_NETWORK') {
            attempt += 1;
            if (attempt >= maxRetries) {
              setNetworkErr(true);
            } else {
              await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
          }
        }
      }
    }
  
    setActionExecuting(false);
  }

  return {
    paymentData,
    actionExecuting,
    getPaymentData,
  };
};