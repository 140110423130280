import create from "zustand";

interface ImageModalStore {
  isShownImage: boolean;
  src: string;
  setIsShownImage: (isShownImage: boolean) => void;
  setSrc: (src: string) => void;
}

const useImageModalStore = create<ImageModalStore>((set) => ({
    isShownImage: false,
    src: '',
    setIsShownImage: (isShownImage) => set({ isShownImage }),
    setSrc: (src) => set({ src }),
}));

export default useImageModalStore;
