import styles from "./PayMethod.module.scss";
import Hash from "components/Hash/Hash";
import Layout from "layout/Layout";
import { useEffect, useState } from "react";
import SberPaySVG from "svg/payMethods/SberPaySVG";
import { Button } from "UI/Button/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal } from "UI/Modal/Modal";
import useUrlData from "store/DataStore";
import TimerComponent from "components/Timer/Timer";
import useTimerData from "store/TimerStore";
import { usePaymentData } from "hooks/usePaymentData";
import { Loader } from "UI/Loader/Loader";
import useTokenStore from "store/TokenStore";
import useDeviceStore from "store/DeviceStore";
import { getDeviceInfo } from "common/commonFunctions";
import PayBtn from "components/PayBtn/PayBtn";
import useErrorStore from "store/errorStore";
import SbpBtnPay from "components/SbpBtnPay/SbpBtnPay";

const PayMethod = () => {
  const navigate = useNavigate();
  const allData = useUrlData((state) => state.data);
  const {t} = useTranslation()
  const [onCancel, setOnCancel] = useState(false)
  const timeLimit = useTimerData((state) => state.end);
  const { getPaymentData, actionExecuting} = usePaymentData();
  const { setToken } = useTokenStore();
  const { setDevice, device } = useDeviceStore();
  const { networkErr } = useErrorStore((state) => state);

  const qrHandler = () => {
    navigate("/qrpay");
  }

  useEffect(() => {
    if(timeLimit){
      navigate('/canceled',{state: 'TIMEISUP'})
    }
  },[navigate, timeLimit])

  useEffect(() => {
    allData.status_name === 'COMPLETED' && navigate('/success');
    allData.status_name === 'CANCELED' && navigate('/canceled',{ state: 'CANCELED' });
      if(networkErr){
        navigate('/canceled', {state: 'NETWORKERR'});
      }

    if (allData.payment_id === '') {
      getPaymentData(sessionStorage.getItem('p_token')!);
      setToken(sessionStorage.getItem('p_token')!)
      getDeviceInfo(setDevice);
    } 
    else if(sessionStorage.getItem('p_token') === null) {
      navigate('/canceled',{ state: 'NOPAYMENT' });
    }
  }, [allData,networkErr]);
  
  return (
    <Layout headerText={t("Выберите способ оплаты")} headerStep={t("1. Метод оплаты.")}>
      {!actionExecuting ?
      <div className={styles.wrapper}>
      <div className={styles.up}>
        <Hash />
        <TimerComponent />
      </div>

      <div className={styles.content}>
        <div className={styles.info}>
          <div className={styles.value}>{allData.amount} ₽</div>
          <div className={styles.recipient}>
            <span>{t("Получатель")}</span>
            <p>{allData.full_name}</p>
          </div>
        </div>
        <div className={styles.variants} style={allData.bank_name !== 'Сбербанк' ? {display: 'flex'}:{}}>
          
          {allData.bank_name === 'Сбербанк' && allData.phone_number !== null  &&  device === 'pc' ? 
          <>
            <div onClick={qrHandler}>
              <SberPaySVG height="60" width="372" className={styles.paySvg} />
            </div>
          </>
          :
          <></>}

        {allData.bank_name === 'Сбербанк' && allData.phone_number !== null  &&  device !== 'pc' ? 
          <>
            <a href={`https://www.sberbank.com/sms/pbpn?requisiteNumber=[${allData.phone_number}]`}>
              <SberPaySVG height="60" width="372" className={styles.paySvg} />
            </a>
          </>
          :
          <></>}

          {allData.bank_name === 'Сбербанк' &&
            <div className={styles.or}>
              <div />
                <span>{t('или')}</span>
              <div />
            </div>
          }

          {allData.phone_number !== null ?
            <div onClick={() => navigate("/payment")}>
              <SbpBtnPay />
            </div>
          :''}

          {allData.bank_card_number !== null ?
            <div onClick={() => navigate("/payment")}>
              <PayBtn />
            </div>
          :''}


        </div>
        <div className={styles.buttons}>
          <Button onClick={() => setOnCancel(true)} variant="link">{t("Отменить платеж")}</Button>
        </div>
      </div>
    </div>
    :
    <div className="loaderBlock">
      <Loader />
    </div>
    }
      
      {onCancel && <Modal type="cancel" goTo={() => navigate("/cancelrequest")} onClose={() => setOnCancel(false)}/>}
    </Layout>
  );
};

export default PayMethod;
