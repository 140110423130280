import styles from "./Canceled.module.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "layout/Layout";
import Hash from "components/Hash/Hash";
import CancelIllustrationSVG from "svg/illustrations/CancelIllustrationSVG";
import { CancelPaymentSVG } from "svg/icons/CancelPaymentSVG";
import { Modal } from "UI/Modal/Modal";
import useDeviceStore from "store/DeviceStore";
import { getDeviceInfo } from "common/commonFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import useErrorStore from "store/errorStore";
import useTokenStore from "store/TokenStore";
import useUrlData from "store/DataStore";
import { usePaymentData } from "hooks/usePaymentData";
import { Loader } from "UI/Loader/Loader";

const Canceled = () => {
  const { t } = useTranslation();
  const [onAccept, setOnAccept] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const allData = useUrlData((state) => state.data);
  const { setToken } = useTokenStore();
  const { setDevice } = useDeviceStore();
  const { networkErr } = useErrorStore((state) => state);
  const { getPaymentData, actionExecuting } = usePaymentData();

  
  useEffect(() => {
    allData.status_name === 'COMPLETED' && navigate('/success');
    allData.status_name === 'CANCELED' && navigate('/canceled',{ state: 'CANCELED' });
      if(networkErr){
        navigate('/canceled', {state: 'NETWORKERR'});
      }

    if (allData.payment_id === '') {
      getPaymentData(sessionStorage.getItem('p_token')!);
      setToken(sessionStorage.getItem('p_token')!)
      getDeviceInfo(setDevice);
      
    } 
    else if(sessionStorage.getItem('p_token') === null) {
      navigate('/canceled',{ state: 'NOPAYMENT' });
    }
  }, [allData,networkErr]);
  
  return (
    <Layout
      headerText={''}
      headerStep={location?.state === 'NETWORKERR' ? t("Ошибка подключения к сети") :t("3.Платеж отменен.")}
      currentStep={3}
    >
      {!actionExecuting ?
      <div className={styles.wrapper}>
        <div className={styles.up}>
          <Hash />

          <CancelPaymentSVG width="42" height="42" />
        </div>

        <div className={styles.content}>
          <CancelIllustrationSVG width="175" height="200" />

          {location?.state === 'CANCELED' &&
            <>
              <p>{t("Платеж отменен")}</p>
              <span>{t("Новую заявку на оплату вы можете создать на сайте.")}</span>
            </>
          }

          {location?.state === 'NETWORKERR' &&
            <>
              <p>{t("Ошибка подключения к сети")}</p>
              <span>{t("Попробуйте еще раз позже")}</span>
            </>
          }

          {location?.state === 'TIMEISUP' &&
            <>
              <p>{t("Время на оплату истекло. Вы можете создать новую заявку на оплату.")}</p>
            </>
          }

          {location?.state === 'NOPAYMENT' &&
            <>
              <p>{t("Платеж не найден")}</p>
            </>
          }

          {location?.state === null && 
            <>
              <p>{t("Что-то пошло не так")}</p>
              <span>{t("Попробуйте еще раз позже")}</span>
            </>
          }

        </div>
      </div>
      :
      <div className="loaderBlock">
        <Loader />
      </div>
    }
      {onAccept && <Modal type="accept" onClose={() => setOnAccept(false)} />}
    </Layout>
  );
};

export default Canceled;