import { Routes, Route } from "react-router-dom";
import { mobileRoutes } from "routes";
// import { mobileRoutes, routes } from "routes";
import { useWindowDimensions } from "hooks/useWindowDimensions";
import useDeviceStore from "store/DeviceStore";

const Root = () => {

  const { device } = useDeviceStore();
  return (
    <div className={device !== 'pc' ? "app" : "app app_pc"}>
      {
        device !== 'pc' ? 
        <Routes>
        {mobileRoutes.map(({ path, Component }, count) => (
          <Route
            key={count}
            path={path}
            element={Component ? <Component /> : <></>}
          />
        ))}
      </Routes> :
      <Routes>
      {mobileRoutes.map(({ path, Component }, count) => (
        <Route
          key={count}
          path={path}
          element={Component ? <Component /> : <></>}
        />
      ))}
    </Routes>
      }
      
    </div>
  );
};

export default Root;
