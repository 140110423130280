import React, { FC, useEffect, useState } from "react";
import styles from "./CopyButton.module.scss";
import copy from "copy-to-clipboard";
import useNotifyStore from "store/NotifyStore";

interface Props {
  value: any;
  children?: React.ReactNode;
  message: string;
}

export const CopyButton: FC<Props> = ({ value, children, message }) => {
  const [showCheck, setShowCheck] = useState(false);
  const { setCopied, isCopied, message: currentMessage } = useNotifyStore();
  const [timer, setTimer] = useState<number | null>(null); // Типизация таймера

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  const handleCopy = (e: React.MouseEvent) => {
    e.stopPropagation();


    if (isCopied && (currentMessage !== message || isCopied) ) {
      setShowCheck(false);
      setCopied(false, "");
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
    }

    copy(value);
    setShowCheck(true);
    setCopied(true, message);

    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = window.setTimeout(() => {
      setShowCheck(false);
      setCopied(false, "");
    }, 2000);
    setTimer(newTimer);
  };

  return (
    <button onClick={handleCopy} className={styles.copyText} type="button">
      {children}
    </button>
  );
};