import create from "zustand";

interface IError {
  networkErr: boolean;
  setNetworkErr: (err: boolean) => void;
}

const useErrorStore = create<IError>((set) => ({
    networkErr: false,
    setNetworkErr: (networkErr) => set({ networkErr }),
}));

export default useErrorStore;
