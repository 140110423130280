import { FC, useEffect, useRef, useState } from "react";
import styles from "./Hash.module.scss";
import cn from "classnames";
import CopySVG from "svg/icons/CopySVG";
import LockSVG from "svg/icons/LockSVG";
import Arrow from "svg/icons/Arrow";
import { useTranslation } from "react-i18next";
import { CopyButton } from "UI/CopyButton/CopyButton";
import useUrlData from "store/DataStore";

interface HashProps {}

const Hash: FC<HashProps> = () => {
  const [showFullHash, setShowFullHash] = useState(false);
  const hashRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const allData = useUrlData((state) => state.data);

  const displayShortHash = (hash: any) => {
    return hash.substring(0, 7) + "..." + hash.substring(hash.length - 4);
  };

  const toggleFullHash = () => {
    setShowFullHash(!showFullHash);
  };

  const handleClickOutside = (event: any) => {
    if (hashRef.current && !hashRef.current.contains(event.target)) {
      setShowFullHash(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.hashCont}>
      <div className={ showFullHash ? cn(styles.arrow, styles.active) : styles.arrow} onClick={toggleFullHash}>
        <Arrow stroke={showFullHash ? "#589D00" : "black"}/>
      </div>
      <span className={styles.hash}>{displayShortHash(allData.payment_id)}</span>
      <LockSVG width="16" height="16" />

      {showFullHash && (
        <div className={cn(styles.tooltip, styles.bar)} ref={hashRef}>
          <div className={styles.info}>
            <span> {t("Идентификатор")}:</span>
            <p>{allData.payment_id}</p>
          </div>

          <div className={styles.copy}>
            <CopyButton message={t("Идентификатор скопирован")} value={allData.payment_id}>
              <CopySVG />
            </CopyButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default Hash;
