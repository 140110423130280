import React, { FC } from "react";
import { ISVG } from "types/ISVG";

export const AttentionSVG: FC<ISVG> = ({width, height}) => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="42" height="42" rx="21" fill="#FFF0A5"/>
      <path d="M21 22L21 17" stroke="#EFA109" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21 24C20.44 24 20 24.44 20 25C20 25.56 20.44 26 21 26C21.56 26 22 25.56 22 25C22 24.44 21.56 24 21 24Z" fill="#EFA109"/>
      <path d="M23.0296 12.1399L30.6896 25.5399C31.5796 27.0999 30.4595 29.0399 28.6595 29.0399H13.3396C11.5496 29.0399 10.4196 27.0999 11.3096 25.5399L18.9695 12.1399C19.8695 10.5699 22.1296 10.5699 23.0296 12.1399Z" stroke="#EFA109" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
}
