import { useTranslation } from 'react-i18next';
import styles from './paybtn.module.scss'

const PayBtn = () => {
  const {t} = useTranslation()

  return (
    <div className={styles.btnBlock}>
      <span>{t('Оплатить')}</span>
    </div>
  );
};

export default PayBtn;