import React from "react";

function Visa() {
  return (
    <svg
      width="35"
      height="11"
      viewBox="0 0 35 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.4147 0.0830078C20.0485 0.0830078 17.9338 1.31487 17.9338 3.59031C17.9338 6.20011 21.6841 6.38035 21.6841 7.69161C21.6841 8.24372 21.0543 8.73787 19.9781 8.73787C18.4512 8.73787 17.31 8.0474 17.31 8.0474L16.8216 10.3439C16.8216 10.3439 18.1363 10.9272 19.8817 10.9272C22.4687 10.9272 24.5046 9.63505 24.5046 7.32041C24.5046 4.56254 20.7387 4.38766 20.7387 3.17087C20.7387 2.73836 21.2558 2.26465 22.3286 2.26465C23.5392 2.26465 24.5267 2.76684 24.5267 2.76684L25.0047 0.549018C25.0047 0.549018 23.9299 0.0830078 22.4143 0.0830078H22.4147ZM0.765386 0.250517L0.708008 0.5852C0.708008 0.5852 1.70345 0.76812 2.60016 1.13329C3.75473 1.55173 3.83679 1.79562 4.03128 2.55209L6.14994 10.7543H8.99016L13.3656 0.250517H10.532L7.7205 7.3921L6.57327 1.33832C6.46819 0.645503 5.9351 0.250182 5.28292 0.250182H0.76572L0.765386 0.250517ZM14.5051 0.250517L12.2824 10.7543H14.9845L17.1993 0.250517H14.5051ZM29.5756 0.250517C28.9241 0.250517 28.5788 0.600946 28.3256 1.21302L24.3669 10.7543H27.2004L27.7485 9.16401H31.2006L31.5338 10.7543H34.0341L31.8531 0.250517H29.5756ZM29.9442 3.08846L30.7842 7.02995H28.5341L29.9446 3.08846H29.9442Z"
        fill="#9C99A6"
      />
    </svg>
  );
}

export default Visa;
