import { Button } from 'UI/Button/Button'
import { useTranslation } from 'react-i18next'

export const ModalCancel = ({goTo, onClose}: {goTo?: () => void, onClose?: () => void}) => {

  const {t} = useTranslation()

  return (
    <>
      <p>{t("После отмены платежа возврат средств не будет возможен. Если вы совершили перевод, то дождитесь завершения обработки платежа.")}</p>
      <Button variant='red' onClick={goTo}>{t("Подтвердить")}</Button>
      <Button onClick={onClose} variant='outline'>{t("Отмена")}</Button>
    </>
  )
}