import React, { FC } from "react";
import { ISVG } from "types/ISVG";

export const CancelPaymentSVG: FC<ISVG> = ({width, height}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="42" height="42" rx="21" fill="#FFC3C6"/>
      <path d="M29.4798 12.5202L12.5065 29.4935M21 9C27.6274 9 33 14.3726 33 21C33 27.6274 27.6274 33 21 33C14.3726 33 9 27.6274 9 21C9 14.3726 14.3726 9 21 9Z" stroke="#EC495C" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

