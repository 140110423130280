import React, { FC } from "react";
import { ISVG } from "types/ISVG";

export const DoneSVG: FC<ISVG> = ({width, height}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={42} height={42} rx="21" fill="#F8F956"/>
      <path d="M30 15L17.625 27L12 21.5455" stroke="#689507" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}