import styles from './sbpbtnpay.module.scss'
import { useTranslation } from "react-i18next";

const SbpBtnPay = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.btnBlock}>
        <img src={"/banks/SBP.svg"} alt="" />
        <span>{t("Оплатить через СБП")}</span>
      </div>
    </div>
  );
}


export default SbpBtnPay;