import create from "zustand";

interface DeviceStore {
  device: string;
  setDevice: (message: string) => void;
}

const useDeviceStore = create<DeviceStore>((set) => ({
    device: "",
    setDevice: (device) => set({ device }),
}));

export default useDeviceStore;
