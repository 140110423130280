import React, { FC } from "react";
import { ISVG } from "types/ISVG";

const LogoIllustrationSVG: FC<ISVG> = ({width, height}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 200 200"
    >
      <path
        fill="#000"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.816"
        d="M39.1 162.387c-.807-.226-1.712-.079-2.559.512l-4.626 3.219c-.847.59-1.752.738-2.56.512l27.463 7.855c.798.226 1.713.078 2.56-.512l4.626-3.219c.847-.591 1.762-.738 2.56-.512L39.1 162.387z"
      ></path>
      <path
        fill="#F8F956"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.816"
        d="M111.004 20.545l-1.939-5.394c-.384-1.083-1.132-1.782-2.008-2.038L79.594 5.258c.876.246 1.624.955 2.008 2.038l1.939 5.394c.384 1.073 1.122 1.772 1.998 2.028l27.463 7.855c-.876-.246-1.614-.945-1.998-2.028z"
      ></path>
      <path
        fill="#000"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.816"
        d="M59.436 180.509c-.807-.226-1.712-.079-2.559.512l-4.626 3.219c-.857.59-1.762.748-2.57.512l27.464 7.855c.807.226 1.722.078 2.569-.512l4.626-3.219c.847-.591 1.762-.738 2.56-.512l-27.464-7.855z"
      ></path>
      <path
        fill="#F8F956"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.816"
        d="M137.78 18.055l-1.939-5.394c-.384-1.073-1.132-1.772-1.998-2.028L106.38 2.778c.876.246 1.614.955 1.998 2.028l1.939 5.394c.384 1.073 1.122 1.772 1.998 2.027l27.463 7.855c-.876-.246-1.614-.945-1.998-2.027z"
      ></path>
      <path
        fill="#000"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.816"
        d="M86.21 178.028c-.808-.226-1.723-.079-2.57.512l-15.7 10.906c-.846.591-1.762.739-2.56.512l27.464 7.855c.807.227 1.713.079 2.56-.512l15.699-10.906c.857-.591 1.772-.748 2.569-.512l-27.463-7.855z"
      ></path>
      <path
        fill="#F8F956"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.816"
        d="M151.558 17.877c-.384-1.083-1.132-1.782-2.008-2.038l-27.463-7.855c.876.246 1.624.955 2.008 2.038l6.556 18.299c.383 1.073 1.132 1.771 1.998 2.027l27.463 7.855c-.876-.246-1.615-.954-1.998-2.027l-6.556-18.3z"
      ></path>
      <path
        fill="#F8F956"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.816"
        d="M139.846 26.628c1.771-1.23 3.829-.542 4.567 1.516l15.818 44.127c.748 2.077-.098 4.775-1.87 6.005l-4.627 3.219c-1.771 1.23-2.618 3.927-1.87 5.984l6.556 18.299c.748 2.077-.098 4.774-1.88 6.005l-4.617 3.209c-1.781 1.24-2.628 3.937-1.88 5.994l1.939 5.394c.739 2.067-.108 4.765-1.87 5.985l-15.7 10.907c-1.782 1.24-2.628 3.937-1.88 5.994l1.939 5.394c.738 2.067-.108 4.764-1.87 5.985l-37.848 26.292c-1.772 1.23-3.829.541-4.567-1.516l-1.94-5.394c-.737-2.067-2.795-2.747-4.576-1.506l-15.7 10.906c-1.772 1.23-3.83.541-4.568-1.516l-1.94-5.394c-.737-2.067-2.795-2.746-4.566-1.516l-4.627 3.219c-1.781 1.24-3.839.551-4.577-1.526l-6.556-18.299c-.738-2.067-2.795-2.746-4.567-1.515l-4.626 3.218c-1.772 1.231-3.83.542-4.568-1.525l-15.818-44.128c-.738-2.067.108-4.764 1.87-5.985l4.627-3.219c1.772-1.23 2.618-3.927 1.87-5.984l-6.556-18.3c-.748-2.076.099-4.773 1.88-6.004l4.627-3.219c1.771-1.23 2.618-3.927 1.87-5.984l-1.94-5.394c-.737-2.067.11-4.765 1.871-5.985l15.7-10.907c1.782-1.24 2.628-3.937 1.88-5.994l-1.939-5.394c-.748-2.077.099-4.774 1.87-6.005L77.035 5.78c1.772-1.23 3.83-.542 4.568 1.525l1.939 5.395c.738 2.067 2.795 2.746 4.577 1.506l15.7-10.907c1.772-1.23 3.829-.541 4.567 1.516l1.939 5.394c.739 2.067 2.796 2.746 4.577 1.506l4.617-3.209c1.782-1.24 3.839-.551 4.577 1.526l6.556 18.299c.738 2.067 2.795 2.746 4.567 1.516l4.627-3.22z"
      ></path>
      <path
        fill="#000"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.816"
        d="M132.56 160.674l-37.847 26.292c-.847.591-1.762.738-2.56.512l27.463 7.855c.807.226 1.713.079 2.56-.512l37.847-26.292-27.463-7.855z"
      ></path>
      <path
        fill="#F8F956"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.816"
        d="M132.502 149.286l1.939 5.394c.738 2.067-.108 4.764-1.87 5.985l27.463 7.855c1.772-1.231 2.618-3.928 1.87-5.985l-1.939-5.394-27.463-7.855z"
      ></path>
      <path
        fill="#000"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.816"
        d="M158.362 78.266l-4.627 3.219c-1.771 1.23-2.618 3.927-1.87 5.985l27.463 7.855c-.738-2.068.109-4.764 1.87-5.985l4.627-3.219-27.463-7.855z"
      ></path>
      <path
        fill="#F8F956"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.816"
        d="M171.876 35.998c-.384-1.072-1.132-1.771-1.998-2.027l-27.463-7.855c.876.246 1.614.954 1.998 2.027l15.819 44.128c.748 2.077-.099 4.774-1.871 6.005l27.463 7.855c1.772-1.23 2.619-3.928 1.87-6.005l-15.818-44.128z"
      ></path>
      <path
        fill="#000"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.816"
        d="M150.093 132.385l-15.7 10.906c-1.782 1.24-2.619 3.938-1.881 5.995l27.463 7.855c-.738-2.067.109-4.764 1.88-5.995l15.701-10.906-27.463-7.855zM156.529 111.782l-4.627 3.209c-1.781 1.24-2.628 3.937-1.88 5.995l27.463 7.855c-.738-2.067.109-4.764 1.88-5.995l4.617-3.209-27.463-7.855h.01z"
      ></path>
      <path
        fill="#F8F956"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.816"
        d="M150.022 120.996l1.94 5.394c.738 2.067-.109 4.764-1.871 5.985l27.463 7.855c1.772-1.23 2.619-3.927 1.871-5.985l-1.94-5.394-27.463-7.855zM151.854 87.48l6.556 18.298c.748 2.077-.098 4.774-1.88 6.005l27.463 7.855c1.782-1.241 2.628-3.938 1.88-6.005l-6.556-18.298-27.463-7.855z"
      ></path>
      <path
        fill="#000"
        d="M104.803 91.29l4.626 12.904-11.083 7.697-11.074 7.688-11.084 7.698-7.855 5.453c-1.772 1.23-2.618 3.927-1.87 5.985l1.94 5.394c.737 2.067 2.795 2.756 4.566 1.525l7.855-5.453c15.677-10.887 18.222-12.659 33.232-23.082l7.855-5.454c1.771-1.23 2.608-3.937 1.87-6.004l-3.278-9.154-3.278-9.155c-.738-2.067-2.795-2.746-4.567-1.516l-7.855 5.454v.02z"
      ></path>
      <path
        fill="#000"
        d="M66.943 101.457l3.278 9.154c.748 2.077 2.796 2.757 4.577 1.526l7.855-5.453 11.074-7.688 11.084-7.697-3.278-9.155c-.748-2.076-2.796-2.756-4.577-1.525L89.1 86.072 78.027 93.76l-11.084 7.697z"
      ></path>
      <path
        fill="#000"
        d="M57.695 75.628l-7.855 5.453c-1.772 1.23-2.618 3.928-1.87 6.005l3.278 9.154 3.278 9.155c.738 2.067 2.795 2.746 4.567 1.515l7.855-5.453-4.627-12.904 11.084-7.698 11.074-7.688 11.083-7.697 7.855-5.453c1.772-1.23 2.619-3.928 1.871-6.005l-1.93-5.384c-.748-2.077-2.795-2.756-4.567-1.526l-7.855 5.453C75.82 63.053 77 62.221 57.696 75.628z"
      ></path>
    </svg>
  );
}

export default LogoIllustrationSVG;