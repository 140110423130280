import axios from 'axios'
import { useState, useEffect, useRef } from 'react'
import useTokenStore from 'store/TokenStore'
import  { consts } from 'consts'
interface IPaymentStatus {
  data: string
}

export const usePaymentStatus = () : {
  paymentStatus: string,
  actionExecuting: boolean,
  getPaymentStatus: (token: string) => void
} => {
  const [paymentStatus, setPaymentStatus] = useState('')
  const [prevStatus, setPrevStatus] = useState('')
  const [actionExecuting, setActionExecuting] = useState(false)
  const { BaseUrl } = consts()

  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  async function getPaymentStatus(token: string) {
    setActionExecuting(true)
    try {
      const response: IPaymentStatus = await axios.get(
        `${BaseUrl}pay/api/v1/pay_form/status/${token}`,
      )
      const newStatus = response.data
      setPaymentStatus(newStatus)
      
      if (newStatus !== prevStatus) {
        setPrevStatus(newStatus)
        clearInterval(intervalRef.current as NodeJS.Timeout)
      }
      
    } catch (error) {
      console.log(error)
    } finally {
      setActionExecuting(false)
    }
  }
  
  const token = useTokenStore((state) => state.token);
  useEffect(() => {
    if (paymentStatus === prevStatus && paymentStatus !== '') {
      intervalRef.current = setInterval(() => {
        getPaymentStatus(token)
      }, 5000)
    }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current)
    }
  }, [paymentStatus, prevStatus])

  return {
    paymentStatus,
    actionExecuting,
    getPaymentStatus,
  }
}