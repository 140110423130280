import create from "zustand";

export interface IUrlDataInit {
  data: {
    payment_id: string;
    amount: number;
    bank_name: string;
    bank_card_number: string | null;
    phone_number: string;
    full_name: string;
    transfer_method: string;
    status_name: string;
    geo_code: string;
    expires_in: number;
    bic?: string | null;
    account_number?: string | null;
  },
}

interface IUrlData extends IUrlDataInit {
  setData: (data: IUrlDataInit['data']) => void;
}

export const initialState: IUrlDataInit = {
  data: {
    geo_code: '',
    transfer_method: '',
    amount: 0,
    bank_name: '',
    bank_card_number: null,
    phone_number: '',
    full_name: '',
    payment_id: '',
    status_name: '',
    expires_in: 0,
    bic: null,
    account_number: null,
  },
};

const useUrlData = create<IUrlData>((set) => ({
  data: initialState.data,
  setData: (newData) => set((state) => ({
    data: {
      ...state.data,
      ...newData,
    },
  })),
}));

export default useUrlData;