import React from "react";

function MasterCard() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="15"
      fill="none"
      viewBox="0 0 23 15"
    >
      <path
        fill="#9C99A6"
        d="M22.051 12.725v-.167h.11v-.056h-.213.055v.278l.048-.055zM22.486 12.725v-.223l-.11.167-.111-.167h-.056v.278-.222l.111.167h.056l.055-.167v.223l.055-.056zM8.315 7.505a7.56 7.56 0 012.74-5.88A6.93 6.93 0 001.53 3.111C-.845 6.218-.315 10.66 2.733 13.106a6.873 6.873 0 008.321.278 7.499 7.499 0 01-2.74-5.88zM21.228 3.111c-2.265-2.98-6.469-3.639-9.525-1.486a7.581 7.581 0 012.787 5.88 7.593 7.593 0 01-2.787 5.88 6.925 6.925 0 003.998 1.263 7.073 7.073 0 004.98-2.114 7.096 7.096 0 002.027-5.03 7.22 7.22 0 00-1.48-4.393z"
      ></path>
      <path
        fill="#9C99A6"
        d="M11.379 1.903A7.21 7.21 0 008.71 7.505c0 2.177.981 4.235 2.668 5.601a7.184 7.184 0 002.668-5.601 7.21 7.21 0 00-2.668-5.602z"
      ></path>
    </svg>
  );
}

export default MasterCard;