import React, { FC } from "react";
import { ISVG } from "types/ISVG";

const DoneIllustrationSVG: FC<ISVG> = ({width, height}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 175 200"
    >
      <path
        fill="#F8F956"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.873"
        d="M78.348 33.617l-2.99-3.576c-.39-.462-.85-.722-1.343-.793l-31.41-4.535c.484.07.953.331 1.343.793l2.99 3.576c.39.462.85.722 1.343.793l31.41 4.535c-.484-.07-.953-.331-1.343-.793zM98.887 18.837l-2.989-3.584c-.381-.461-.85-.722-1.325-.79l-31.41-4.536c.484.07.944.33 1.326.791l2.989 3.584c.381.461.85.723 1.334.792l31.41 4.535c-.485-.06-.944-.33-1.335-.792z"
      ></path>
      <path
        fill="#000"
        d="M84.47 179.459l-31.41-4.535c-.95-.137-1.985.462-2.694 1.691l-2.796 4.877c-.71 1.238-1.744 1.829-2.694 1.691l31.41 4.535c.95.137 1.985-.462 2.694-1.691l2.796-4.877c.71-1.237 1.743-1.828 2.694-1.691z"
      ></path>
      <path
        fill="#000"
        d="M105.462 184.914l-31.41-4.535c-.95-.137-1.985.462-2.694 1.691l-2.796 4.877c-.71 1.238-1.743 1.829-2.694 1.691l31.41 4.535c.95.138 1.985-.462 2.694-1.691l2.796-4.877c.71-1.237 1.743-1.828 2.694-1.691z"
      ></path>
      <path
        fill="#F8F956"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.873"
        d="M119.878 24.301l-10.154-12.15c-.382-.461-.851-.722-1.335-.792l-31.41-4.535c.484.07.944.33 1.335.792l10.154 12.15c.382.461.842.721 1.325.791l31.41 4.535c-.484-.07-.944-.33-1.325-.791z"
      ></path>
      <path
        fill="#000"
        d="M125.992 170.133l-31.41-4.535c-.95-.137-1.985.462-2.694 1.691l-9.483 16.55c-.711 1.247-1.754 1.845-2.704 1.707l31.409 4.535c.951.138 1.985-.462 2.705-1.707l9.483-16.55c.71-1.237 1.743-1.828 2.694-1.691z"
      ></path>
      <path
        fill="#F8F956"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.873"
        d="M125.653 123.283c1.147 1.366 1.201 4.019.139 5.88l-22.857 39.895c-1.072 1.878-2.882 2.283-4.03.917l-2.989-3.584c-1.148-1.367-2.958-.962-4.02.9l-9.482 16.549c-1.073 1.878-2.892 2.282-4.03.917l-2.99-3.584c-1.146-1.375-2.966-.971-4.028.899l-2.796 4.877c-1.071 1.869-2.882 2.273-4.02.9l-10.146-12.14c-1.148-1.367-2.958-.962-4.028.899l-2.796 4.877c-1.07 1.86-2.882 2.273-4.029.899l-24.459-29.27c-1.148-1.366-1.2-4.018-.14-5.879l2.797-4.877c1.07-1.861 1.008-4.514-.14-5.88l-10.154-12.15c-1.148-1.366-1.2-4.018-.14-5.879l2.797-4.877c1.07-1.861 1.008-4.514-.14-5.88l-2.989-3.584c-1.147-1.366-1.2-4.018-.128-5.896l9.483-16.55c1.07-1.86 1.008-4.513-.139-5.888L17.21 72.2c-1.146-1.375-1.208-4.028-.137-5.897L39.93 26.407c1.07-1.86 2.882-2.274 4.028-.9l2.99 3.576c1.147 1.375 2.966.971 4.028-.899l9.483-16.55c1.073-1.877 2.883-2.282 4.03-.916l2.99 3.584c1.147 1.366 2.958.962 4.028-.899l2.796-4.877c1.07-1.86 2.881-2.274 4.02-.9l10.153 12.15c1.148 1.366 2.959.961 4.03-.916l2.787-4.87c1.072-1.877 2.883-2.282 4.03-.916l24.46 29.27c1.147 1.374 1.208 4.027.137 5.896l-2.786 4.87c-1.072 1.877-1.009 4.522.129 5.895l10.146 12.14c1.148 1.367 1.201 4.019.14 5.88l-2.796 4.877c-1.07 1.86-1.009 4.513.147 5.89l2.988 3.583c1.148 1.366 1.201 4.018.129 5.896l-9.483 16.55c-1.07 1.86-1.008 4.513.14 5.879l2.988 3.584-.008-.001z"
      ></path>
      <path
        fill="#000"
        d="M82.251 51.668c1.148 1.366 1.201 4.018.14 5.88l-4.737 8.27-6.685 11.665-4.737 8.27c-1.072 1.878-2.883 2.283-4.03.917l-2.99-3.584c-1.147-1.366-1.2-4.018-.128-5.896l4.737-8.27 6.686-11.665 4.736-8.271c1.07-1.86 2.882-2.274 4.028-.9l2.99 3.585-.01-.001zM103.713 77.36c1.146 1.375 1.208 4.028.147 5.89l-4.737 8.271-6.686 11.664-4.736 8.271c-1.072 1.878-2.883 2.283-4.038.907l-2.99-3.576c-1.147-1.374-1.209-4.027-.137-5.896l4.736-8.272 6.686-11.664 4.737-8.27c1.07-1.861 2.881-2.275 4.028-.9l2.99 3.576zM48.349 89.786c1.147 1.366 1.2 4.018.128 5.895l-4.736 8.271-5.07-6.069c-1.147-1.366-1.2-4.018-.128-5.896l2.786-4.87c1.073-1.877 2.883-2.282 4.03-.916l2.99 3.585zM72.367 138.211l-4.736 8.272c-1.07 1.86-2.882 2.273-4.029.898l-5.069-6.069-7.156-8.565-7.157-8.564-5.069-6.07c-1.147-1.366-1.2-4.018-.139-5.879l4.737-8.271 7.156 8.564 7.157 8.565 7.156 8.564 7.157 8.565-.008-.01z"
      ></path>
      <path
        fill="#000"
        d="M84.13 132.609c1.148 1.366 1.202 4.018.13 5.895l-2.787 4.87c-1.072 1.878-2.882 2.282-4.03.916l-5.07-6.069 4.737-8.271c1.073-1.878 2.883-2.283 4.031-.917l2.989 3.584v-.008z"
      ></path>
      <path
        fill="#F8F956"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.873"
        d="M157.202 133.698c1.07-1.86 1.008-4.513-.139-5.88l-2.989-3.584c-1.148-1.366-1.201-4.018-.14-5.879l9.483-16.55c1.073-1.878 1.01-4.522-.128-5.896l-2.989-3.584c-1.147-1.375-1.208-4.028-.147-5.889l2.796-4.877c1.07-1.86 1.008-4.513-.14-5.88l-10.146-12.14c-1.148-1.366-1.201-4.018-.129-5.895l2.795-4.869c1.072-1.877 1.009-4.522-.137-5.897l-24.46-29.269c-.381-.461-.85-.722-1.325-.79l-31.41-4.536c.484.07.944.33 1.326.791l24.459 29.27c1.147 1.374 1.209 4.027.138 5.896l-2.795 4.869c-1.073 1.877-1.01 4.522.128 5.895l10.147 12.14c1.147 1.367 1.201 4.019.139 5.88l-2.796 4.877c-1.07 1.86-1.008 4.513.147 5.89l2.989 3.583c1.148 1.367 1.201 4.019.129 5.896l-9.483 16.55c-1.07 1.86-1.008 4.513.139 5.879l2.989 3.585c1.148 1.366 1.201 4.018.139 5.879l-22.857 39.896c-.711 1.246-1.754 1.844-2.704 1.707l31.409 4.535c.951.137 1.985-.462 2.704-1.699l22.857-39.895.001-.009z"
      ></path>
      <path
        fill="#000"
        d="M100.231 170.766c.95.137 1.985-.462 2.704-1.707l22.857-39.896 31.41 4.535-22.857 39.896c-.711 1.246-1.744 1.836-2.704 1.698l-31.409-4.535-.001.009zM152.672 63.54c-1.148-1.366-1.201-4.018-.129-5.895l2.795-4.869-31.41-4.535-2.795 4.869c-1.072 1.877-1.009 4.522.129 5.895l31.41 4.535zM160.299 92.333c-1.147-1.374-1.209-4.027-.147-5.889l2.796-4.877-31.41-4.535-2.796 4.877c-1.07 1.86-1.008 4.514.147 5.89l31.41 4.534zM154.074 124.234c-1.147-1.366-1.201-4.018-.139-5.879l9.483-16.55-31.41-4.535-9.483 16.55c-1.07 1.86-1.008 4.513.14 5.879l31.409 4.535z"
      ></path>
    </svg>
  );
}

export default DoneIllustrationSVG;
