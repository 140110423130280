import styles from "./Layout.module.scss";
import MasterCard from "svg/payments/MasterCard";
import Visa from "svg/payments/Visa";
import MIR from "svg/payments/MIR";
import JCB from "svg/payments/JCB";
import LangSwitcher from "./LangSwitcher";
import AILogo from "svg/icons/AILogo";
import useNotifyStore from "store/NotifyStore";
import ProgressLoading from "./ProgressLoading";
import { ImageModal } from "UI/Modal/ImageModal";
import useImageModalStore from "store/ImageModalStore";
import cn from 'classnames';
import useDeviceStore from "store/DeviceStore";

const Layout = ({
  headerText,
  children,
  headerStep,
  currentStep = 1,
  loading
}: {
  headerText: string;
  children: any;
  headerStep?: string;
  currentStep?: number;
  loading?: boolean
}) => {

  const { isShownImage } = useImageModalStore() 
  const { message } = useNotifyStore();
  const { device } = useDeviceStore();

  return (
    <div className={cn(styles.wrapper, device === 'pc' ? styles.wrapper_pc : '')}>
      <div className={styles.container}>
        {message && device !== 'pc' && <div style={{padding: '41px 0 15px 0'}}></div>}
        {message ? (
          <>
            <div className={styles.copied} style={device==='pc' ? {borderRadius: '100px 100px 0px 0px'}:{position: 'fixed',top: '0px',left: '0px', zIndex: '10'}} > {message} </div>
          </>
        ) : (
          <div className={styles.header}>
            {headerStep && (<p>{headerStep}</p>)}
            <span>{headerText}</span>
            </div>
        )}

        <div className={styles.progressBar}>
          {loading 
          ? <ProgressLoading />
          :
          <div className={styles.progress} style={{ width: `${(currentStep) * 33.33}%` }}></div>
          }
        </div>
        <div className={styles.content}>{children}</div>
      </div>

      <div className={styles.footer}>
        <div className={styles.payments}>
          <MasterCard />
          <Visa />
          <MIR />
          <JCB />
        </div>

        <div className={styles.options}>
          <div className={styles.lang}>
            <LangSwitcher />
          </div>

          <div className={styles.labels}>
            <p>Secure by</p>
            <AILogo />
          </div>
        </div>
        {isShownImage && <ImageModal />}
      </div>
    </div>
  );
};

export default Layout;
