import { useTranslation } from 'react-i18next';
import { CancelPaymentSVG } from 'svg/icons/CancelPaymentSVG';

export const ModalHeaderCancel = () => {

  const {t} = useTranslation()

  return (
    <>
        <CancelPaymentSVG width='42' height='42'/>
        <p>{t("Подтвердите отмену платежа")}</p>
    </>
  )
}