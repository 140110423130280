import { useTranslation } from 'react-i18next';
import styles from './platiqr.module.scss'

const PlatiQr = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.btnBlock}>
        <div className={styles.logo}>
          <span>{t("Плати QR")}</span>
          <img src={"/banks/BLACKLOGOPAY.svg"} alt="" />
        </div>
      </div>
    </div>
  )
}
export default PlatiQr