import { memo } from "react";
import { useTranslation } from "react-i18next";
import EngBtn from "svg/icons/EngBtn";
import RusBtn from "svg/icons/RusBtn";

const LangSwitcher = memo(() => {
  const { i18n } = useTranslation();

  const toggle = () => {
    i18n.changeLanguage(i18n.language === "ru" ? "en" : "ru");
  };

  return (
    <div>
      <div onClick={toggle} style={{cursor: 'pointer'}}>
        {i18n.language === "ru" ? <RusBtn /> : <EngBtn />}
      </div>
    </div>
  );
});

export default LangSwitcher;
