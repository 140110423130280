import React, { FC } from "react";
import { ISVG } from "types/ISVG";

const LockSVG: FC<ISVG> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.09244 0C5.99852 0 4.12769 1.54417 4.12769 3.6516V6.13653C3.60962 6.14986 3.27928 6.19284 3.00702 6.33157C2.65999 6.50839 2.37784 6.79054 2.20102 7.13757C2 7.53209 2 8.04855 2 9.08146V12.939C2 13.9719 2 14.4884 2.20102 14.8829C2.37784 15.2299 2.65999 15.5121 3.00702 15.6889C3.40154 15.8899 3.918 15.8899 4.95091 15.8899H11.2483C12.2812 15.8899 12.7977 15.8899 13.1922 15.6889C13.5392 15.5121 13.8214 15.2299 13.9982 14.8829C14.1992 14.4884 14.1992 13.9719 14.1992 12.939V9.08146C14.1992 8.04855 14.1992 7.53209 13.9982 7.13757C13.8214 6.79054 13.5392 6.50839 13.1922 6.33157C12.9174 6.19157 12.5835 6.14907 12.0572 6.13617V3.6516C12.0572 1.54417 10.1864 0 8.09244 0ZM10.2273 6.13055V3.6516C10.2273 2.7362 9.36724 1.82988 8.09244 1.82988C6.81764 1.82988 5.95758 2.7362 5.95758 3.6516V6.13055H10.2273Z"
        fill="black"
      />
    </svg>
  );
};

export default LockSVG;
