import styles from './Modal.module.scss'
import useImageModalStore from 'store/ImageModalStore';
import { CloseModalSVG } from "svg/icons/CloseModalSVG"
import cn from 'classnames';
import useDeviceStore from 'store/DeviceStore';

export const ImageModal = () => {

  const { src, setIsShownImage } = useImageModalStore();
  const { device } = useDeviceStore();

  return (
    <>
      <div className={cn(styles.modalImage, device === 'pc' ? styles.modalImage_pc : '')}>
        <div className={styles.modalImageContent}>
        <div className={styles.closeModalImage} onClick={() => setIsShownImage(false)}>
            <CloseModalSVG className={styles.closeModalImage} width='30' height='30'/>
          </div>
          <img src={src} alt="Uploaded" />
        </div>
      </div>
    </>
  )
}