import {FC} from 'react'
import styles from './Modal.module.scss'
import {AttentionSVG} from 'svg/icons/AttentionSVG'
import {CancelPaymentSVG} from 'svg/icons/CancelPaymentSVG'
import {DoneSVG} from 'svg/icons/DoneSVG'
import {EditSVG} from 'svg/icons/EditSVG'

interface Props{
  children?: React.ReactNode;
}

export const ModalHeader: FC<Props> = ({children}) => {
  return (
    <>
        <div className={styles.modalHeader}>
            <div className={styles.modalHeaderContent}>
                {children}
            </div>
        </div>
    </>
  )
}