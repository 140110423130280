import { useEffect, useState } from "react";
import styles from "./TimeIsUp.module.scss";
import { useTranslation } from "react-i18next";
import Layout from "layout/Layout";
import Hash from "components/Hash/Hash";
import CancelIllustrationSVG from "svg/illustrations/CancelIllustrationSVG";
import { CancelPaymentSVG } from "svg/icons/CancelPaymentSVG";
import { Modal } from "UI/Modal/Modal";
import useDeviceStore from "store/DeviceStore";
import { getDeviceInfo } from "common/commonFunctions";

const TimeIsUp = () => {
  const { t } = useTranslation();
  const { setDevice } = useDeviceStore();
  
  useEffect(() => {
    getDeviceInfo(setDevice);
  }, []);
  const [onAccept, setOnAccept] = useState(false);

  return (
    <Layout
      headerText={t("")}
      headerStep={t("Время на оплату истекло.")}
      currentStep={3}
    >
      <div className={styles.wrapper}>
        <div className={styles.up}>
          <Hash />

          <CancelPaymentSVG width="42" height="42" />
        </div>

        <div className={styles.content}>
          <CancelIllustrationSVG width="175" height="200" />
          <p>{t("Платеж отменен")}</p>
          <span>{t("Время на оплату истекло. Вы можете создать новую заявку на оплату.")}</span>
        </div>
      </div>

      {onAccept && <Modal type="accept" onClose={() => setOnAccept(false)} />}

    </Layout>
  );
};

export default TimeIsUp;
