import React, { FC } from "react";
import { ISVG } from "types/ISVG";

const TimerSVG: FC<ISVG> = ({width, height}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11.48 3a9 9 0 107.308 14.253"
      ></path>
      <path
        stroke="#323232"
        strokeDasharray="1 4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19.956 14.5c1.299-4.498-1.226-9.445-5.683-11"
      ></path>
      <path
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14.85 14.87l-3.66-2.17V7.98"
      ></path>
    </svg>
  );
}

export default TimerSVG;
