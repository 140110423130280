import React from "react";

function MIR() {
  return (
    <svg
      width="39"
      height="11"
      viewBox="0 0 39 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.60426 0.313214H0.0341797V10.6989H3.38789V4.5324H3.71245L5.55158 10.6989H8.14801L9.98714 4.5324H10.2035V10.6989H13.6654V0.313214H10.3117C10.2866 0.313214 10.2592 0.312849 10.23 0.312458C9.75549 0.306121 8.77198 0.292987 8.36438 1.71961C8.29697 1.95553 8.19807 2.27544 8.07421 2.67608C7.80048 3.56147 7.40487 4.84112 6.95798 6.47972H6.74161C6.0925 4.20785 5.65977 2.69327 5.33521 1.71961C4.92705 0.291041 4.03766 0.30616 3.66562 0.312484C3.64322 0.312865 3.62269 0.313214 3.60426 0.313214Z"
        fill="#9C99A6"
      />
      <path
        d="M18.5337 0.313214H15.0718V10.6989H18.3173C18.3173 10.6989 19.3992 10.5907 19.8319 9.61707L22.1038 4.5324H22.3202V10.6989H25.7821V0.313214H22.5365C22.5365 0.313214 21.5629 0.421398 21.1301 1.39506L18.8583 6.47972H18.5337V0.313214Z"
        fill="#9C99A6"
      />
      <path
        d="M30.434 10.6989V7.56157H34.1123C35.8432 7.56157 37.3578 6.58791 37.7905 5.28969H27.1885V10.6989H30.434Z"
        fill="#9C99A6"
      />
      <path
        d="M34.3296 0.311523C37.1424 0.311523 38.4406 2.69158 38.0079 4.6389H31.1922C29.1367 4.6389 27.1894 2.58339 26.973 0.311523H34.3296Z"
        fill="#9C99A6"
      />
    </svg>
  );
}

export default MIR;
