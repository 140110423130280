import create from "zustand";

export interface ITimer {
  minutes: number;
  seconds: number;
  end: boolean;
}

interface ITimerData extends ITimer {
  setMinutes(minutes: number): void;
  setSeconds(seconds: number): void;
  setTimer(seconds: number): void;
  decreaseTimer(): void;
}

export const initialState: ITimer = {
  minutes: 0,
  seconds: 0,
  end: false,
};

const useTimerData = create<ITimerData>((set, get) => ({
  ...initialState,
  setMinutes: (minutes: number) => set({ minutes }),
  setSeconds: (seconds: number) => set({ seconds }),
  setEnd: (end: boolean) => set({ end }),
  setTimer: (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    set({ minutes, seconds: remainingSeconds, end: false });
  },

  decreaseTimer: () => {
    const { minutes, seconds } = get();
    if (minutes === 0 && seconds === 0) {
      set({end: true});
      return;
    };

    if (seconds > 0) {
      set({ seconds: seconds - 1 });
    } else if (minutes > 0) {
      set({ minutes: minutes - 1, seconds: 59 });
    }

  },
}));

export default useTimerData;