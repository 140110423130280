import { useTranslation } from 'react-i18next';
import { AttentionSVG } from 'svg/icons/AttentionSVG';

export const ModalHeaderAttention = () => {

  const {t} = useTranslation()

  return (
    <>
        <AttentionSVG width='42' height='42'/>
        <p>{t("Обратите внимание")}</p>
    </>
  )
}