import React, { FC } from "react";
import { ISVG } from "types/ISVG";

const LoaderSVG: FC<ISVG> = ({width, height, className}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 42 42"
      className={className}
    >
      <path
        stroke="url(#paint0_angular_4_11396)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M10.198 15.773A12 12 0 1021 9"
      ></path>
      <defs>
        <radialGradient
          id="paint0_angular_4_11396"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-143.13 14 7) scale(17.5)"
          gradientUnits="userSpaceOnUse"
        >
          <stop></stop>
          <stop offset="0.39"></stop>
          <stop offset="1" stopOpacity="0"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
}

export default LoaderSVG;
