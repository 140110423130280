import { useEffect } from "react";
import useTimerData from "store/TimerStore";
import TimerSVG from "svg/icons/TimerSVG";
import styles from "./timer.module.scss";
import useUrlData from "store/DataStore";

const TimerComponent = () => {
  const { expires_in } = useUrlData((state) => state.data);
  const { minutes, seconds, setTimer, decreaseTimer } = useTimerData();

  useEffect(() => {
    setTimer(expires_in);

    const timerInterval = setInterval(() => {
      decreaseTimer();
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [expires_in, setTimer, decreaseTimer]);

  return (
    <div className={styles.timer}>
      <TimerSVG width="24" height="24" />
      <span style={{ width: '50px', height: '100%', textAlign: 'center' }}>
        {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </span>
    </div>
  );
};

export default TimerComponent;