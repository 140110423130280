export const formatValue = (number: number) => {
    const digits = String(number).split('');
    let formattedNumber = '';
    
    for (let i = 0; i < digits.length; i++) {
      formattedNumber += digits[i];
      if ((digits.length - i - 1) % 3 === 0 && i !== digits.length - 1) {
        formattedNumber += ' ';
      }
    }
    
    return formattedNumber;
  };
  