import MainPage from "pages/MainPage/MainPage"
import PayMethod from "pages/PayMethod/PayMethod"
import Payment from "pages/Payment/Payment"
import CancelPayment from "pages/CancelPayment/CancelPayment"
import TimeIsUp from "pages/TimeIsUp/TimeIsUp"
import SuccessPayment from "pages/SuccessPayment/SuccessPayment"
import CancelRequest from "pages/CancelRequest/CancelRequest"
import QRPay from "pages/QRPay/QRPay"
import searchPay from "pages/SearchPay/SearchPay"
import Canceled from "pages/Canceled/Canceled"

export const INDEX_ROUTE = "/"
export const METHOD_ROUTE = "/method"   
export const PAYMENT_ROUTE = "/payment"
export const SEARCHPAY_ROUTE = "/search"

export const CANCEL_ROUTE = "/cancel"
export const TIMEISUP_ROUTE = "/timeisup"
export const SUCCESS_ROUTE = "/success"
export const CANCELREQUEST_ROUTE = "/cancelrequest"

export const QRPAY_ROUTE = "/qrpay"
export const searchPay_ROUTE = "/searchPay"
export const SBPPAY_ROUTE = "/sbppay"
export const CANCELED_ROUTE = "/canceled"





export const mobileRoutes = [
    {
        path: INDEX_ROUTE + '/:id', 
        Component: MainPage
    },
    {
        path: METHOD_ROUTE , 
        Component: PayMethod,
    },
    {
        path: PAYMENT_ROUTE, 
        Component: Payment,
    },
    {
        path: CANCEL_ROUTE, 
        Component: CancelPayment,
    },
    {
        path: TIMEISUP_ROUTE, 
        Component: TimeIsUp,
    },
    {
        path: SUCCESS_ROUTE, 
        Component: SuccessPayment,
    },
    {
        path: CANCELREQUEST_ROUTE, 
        Component: CancelRequest,
    },
    {
        path: QRPAY_ROUTE, 
        Component: QRPay
    },
    {
        path: searchPay_ROUTE, 
        Component: searchPay
    },
    {
        path: CANCELED_ROUTE, 
        Component: Canceled
    },
]