import React, { FC } from "react";
import { ISVG } from "types/ISVG";

const ProcessingIllustrationSVG: FC<ISVG> = ({width, height}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 244 200"
    >
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.781"
        d="M78.67 34.28l42.157-.734c.312 0 .625.047.937.156l-42.156.734a2.49 2.49 0 00-.937-.156zM79.607 34.444l42.156-.734 4.382 1.586-42.156.734-4.382-1.586zM127.074 35.46l-42.155.734a2.81 2.81 0 01-.938-.157l42.156-.734c.313.117.625.164.937.156zM92.129 25.11l42.156-.735c.312 0 .625.047.937.157l-42.156.734a2.491 2.491 0 00-.937-.156zM93.066 25.274l42.148-.734 4.382 1.585-42.156.734-4.374-1.585zM140.533 26.29l-42.155.734a2.81 2.81 0 01-.938-.157l42.156-.734c.313.117.625.164.937.157zM105.579 15.94l42.156-.734c.312 0 .625.046.937.156l-42.156.734a2.484 2.484 0 00-.937-.156z"
      ></path>
      <path
        fill="#000"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.781"
        d="M149.032 172.307c-1.352.023-2.586 1.125-2.976 2.828l-1.25 5.428c-.39 1.695-1.625 2.804-2.976 2.828l42.156-.735c1.351-.023 2.585-1.132 2.976-2.827l1.25-5.429c.39-1.695 1.624-2.804 2.976-2.827l-42.156.734z"
      ></path>
      <path
        fill="#000"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.781"
        d="M162.482 163.137c-1.351.023-2.585 1.133-2.976 2.828l-1.25 5.42c-.39 1.695-1.624 2.805-2.976 2.828l42.156-.734c1.351-.024 2.585-1.133 2.976-2.828l1.25-5.421c.39-1.695 1.624-2.804 2.976-2.827l-42.156.734z"
      ></path>
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.781"
        d="M122.592 36.998l42.155-.734c4.929-.086 9.952.734 14.935 2.538 26.799 9.74 42.328 44.546 34.688 77.751-6.217 27.026-25.862 44.663-47.404 45.038l-42.156.734c21.543-.375 41.195-18.004 47.405-45.038 7.639-33.204-7.897-68.018-34.689-77.75-4.983-1.812-10.013-2.625-14.934-2.539z"
      ></path>
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.781"
        d="M106.517 16.104l42.155-.734 67.19 24.417-42.148.734-67.197-24.417zM223.352 56.565l-4.374-1.594c-1.679-.61-2.655-2.804-2.179-4.882l1.25-5.42c.476-2.086-.492-4.273-2.179-4.882l-42.156.734c1.687.61 2.656 2.796 2.179 4.882l-1.25 5.42c-.476 2.078.5 4.273 2.18 4.882l4.374 1.594 42.155-.734z"
      ></path>
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.781"
        d="M230.836 73.342l-4.374-1.593c-1.687-.61-2.656-2.804-2.18-4.882l1.25-5.42c.477-2.086-.492-4.273-2.179-4.883l-42.156.735c1.687.609 2.656 2.796 2.18 4.882l-1.25 5.42c-.477 2.078.492 4.273 2.179 4.882l4.374 1.594 42.156-.735z"
      ></path>
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.781"
        d="M102.603 18.767c.477-2.085 2.234-3.28 3.914-2.671l67.189 24.417c1.688.61 2.656 2.796 2.18 4.882l-1.25 5.42c-.477 2.078.5 4.273 2.179 4.883l4.374 1.593c1.687.61 2.656 2.796 2.18 4.882l-1.25 5.42c-.477 2.078.492 4.273 2.179 4.882l4.374 1.594c1.687.61 2.656 2.796 2.179 4.882l-19.152 83.264c-.476 2.086-2.234 3.281-3.913 2.672l-4.374-1.594c-1.688-.609-3.437.578-3.914 2.672l-1.249 5.421c-.477 2.085-2.234 3.28-3.914 2.671l-4.374-1.594c-1.679-.609-3.437.578-3.913 2.672l-1.25 5.421c-.476 2.085-2.234 3.28-3.913 2.671l-67.19-24.417c-1.687-.609-2.656-2.796-2.18-4.882l1.25-5.421c.477-2.085-.492-4.272-2.179-4.882l-4.374-1.593c-1.687-.609-2.656-2.796-2.18-4.882l1.25-5.421c.477-2.085-.492-4.272-2.179-4.882l-4.374-1.593c-1.687-.609-2.655-2.796-2.179-4.882l19.152-83.264c.477-2.086 2.227-3.281 3.914-2.672l4.374 1.594c1.687.609 3.437-.586 3.913-2.672l1.25-5.42c.476-2.086 2.234-3.281 3.913-2.672l4.374 1.594c1.688.609 3.437-.586 3.914-2.672l1.249-5.42z"
      ></path>
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.781"
        d="M233.015 78.224c.476-2.085-.493-4.273-2.18-4.882l-42.155.734c1.687.61 2.655 2.797 2.179 4.882l-19.153 83.265c-.39 1.695-1.624 2.804-2.976 2.828l42.156-.735c1.351-.023 2.586-1.132 2.976-2.827l19.153-83.265z"
      ></path>
      <path
        fill="#000"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.781"
        d="M66.572 176.846c-.68.007-1.367.289-1.937.851l-3.586 3.507c-.578.562-1.257.844-1.944.851l41.218-.703c.687-.007 1.367-.289 1.945-.851l3.585-3.507c.57-.562 1.258-.836 1.937-.851l-41.218.703z"
      ></path>
      <path
        fill="#000"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.781"
        d="M82.061 179.04c-.68.008-1.367.289-1.937.852l-3.585 3.515c-.57.562-1.258.835-1.937.851l41.218-.703c.679-.016 1.367-.289 1.937-.851l3.585-3.515c.57-.563 1.258-.836 1.937-.852l-41.218.703z"
      ></path>
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.781"
        d="M119.788 19.51l-2.89-4.43c-.649-.991-1.609-1.5-2.57-1.483l-41.219.703c.961-.016 1.922.492 2.57 1.484l2.89 4.428c.648.992 1.61 1.508 2.57 1.492l41.218-.703c-.96.016-1.913-.492-2.569-1.492z"
      ></path>
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.781"
        d="M135.269 21.704l-2.89-4.429c-.648-.992-1.609-1.507-2.562-1.484l-41.218.703c.96-.015 1.913.492 2.562 1.484l2.89 4.43c.648.991 1.609 1.507 2.562 1.491l41.218-.703c-.961.016-1.914-.492-2.562-1.492z"
      ></path>
      <path
        fill="#000"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.781"
        d="M147.11 131.69l-55.09 53.911c-.571.563-1.258.836-1.938.852l41.218-.703c.68-.008 1.367-.289 1.938-.852l55.09-53.911-41.218.703zM146.876 97.213l3.585-3.507 41.219-.703-3.585 3.507c-1.383 1.351-1.594 3.827-.493 5.53l-41.218.703c-1.109-1.695-.89-4.179.492-5.53z"
      ></path>
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.781"
        d="M188.82 125.457l-2.89-4.429-41.218.703 2.89 4.429c1.109 1.703.89 4.179-.492 5.53l41.218-.703c1.375-1.343 1.601-3.819.492-5.53z"
      ></path>
      <path
        fill="#000"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.781"
        d="M190 111.991l-41.219.703-3.585 3.507c-1.382 1.352-1.593 3.828-.492 5.53l41.218-.703c-1.109-1.694-.89-4.178.492-5.53l3.586-3.507z"
      ></path>
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.781"
        d="M190.5 106.461l-2.89-4.429-41.218.703 2.89 4.429c1.109 1.703.89 4.179-.492 5.53l41.218-.703c1.383-1.351 1.601-3.827.492-5.53zM192.171 87.472l-44.304-67.994c-.648-1-1.609-1.507-2.569-1.492l-41.219.703c.961-.015 1.914.492 2.57 1.492l44.304 67.994c1.109 1.703.89 4.18-.492 5.53l41.218-.702c1.382-1.352 1.601-3.82.492-5.523v-.008z"
      ></path>
      <path
        fill="#EBE9E9"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.781"
        d="M102.135 19.54c1.382-1.35 3.397-1.062 4.507.641l44.303 67.994c1.11 1.703.891 4.18-.492 5.53l-3.585 3.508c-1.383 1.351-1.593 3.827-.492 5.53l2.89 4.429c1.109 1.702.89 4.179-.492 5.53l-3.585 3.507c-1.383 1.351-1.594 3.827-.492 5.53l2.89 4.429c1.109 1.703.89 4.179-.492 5.53l-55.091 53.911c-1.375 1.344-3.398 1.063-4.507-.64l-2.89-4.429c-1.11-1.695-3.124-1.992-4.507-.641l-3.585 3.515c-1.375 1.344-3.398 1.063-4.507-.64l-2.89-4.429c-1.11-1.695-3.124-1.992-4.507-.64l-3.585 3.507c-1.383 1.351-3.398 1.07-4.515-.633l-44.304-67.994c-1.109-1.703-.89-4.179.492-5.53l3.586-3.508c1.382-1.351 1.6-3.82.492-5.53l-2.89-4.428c-1.11-1.703-.89-4.18.492-5.53l3.585-3.516c1.383-1.35 1.601-3.82.492-5.53l-2.89-4.429c-1.11-1.695-.89-4.178.492-5.53l55.09-53.91c1.375-1.344 3.398-1.063 4.508.64l2.89 4.428c1.109 1.703 3.124 1.992 4.507.64l3.585-3.506c1.382-1.351 3.398-1.07 4.514.633l2.89 4.428c1.11 1.703 3.125 1.992 4.507.64l3.586-3.506z"
      ></path>
      <path
        fill="#000"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M54.254 110.494l-1.93 6.119c-.59 1.87.26 3.77 1.89 4.22l5.33 1.451c1.63.44 3.45-.72 4.04-2.6l3-9.531-8.3-2.26c-1.63-.44-3.45.721-4.04 2.601h.01zM78.954 99.483l-5.33-1.45c-1.63-.44-3.45.72-4.04 2.6l-3 9.531 8.3 2.259c1.63.44 3.45-.72 4.04-2.6l1.93-6.119c.59-1.87-.26-3.77-1.89-4.22h-.01zM98.374 76.683l-8.3-2.26-11.26-3.07-8.3-2.26c-1.63-.44-3.45.72-4.04 2.6l-1.93 6.12c-.59 1.87.26 3.77 1.89 4.22l8.3 2.26 11.26 3.07 11.26 3.07 3-9.53c.59-1.87-.26-3.77-1.89-4.22h.01zM105.554 92.693l-8.3-2.26-4.08 12.941-4.08 12.939-3 9.53c-.59 1.87.26 3.77 1.89 4.22l5.33 1.451c1.63.44 3.45-.72 4.04-2.6l3-9.531 4.08-12.94 3-9.53c.59-1.87-.26-3.77-1.89-4.22h.01z"
      ></path>
    </svg>
  );
}

export default ProcessingIllustrationSVG;
