import { useEffect, useState } from "react";
import Layout from "layout/Layout";
import LogoIllustrationSVG from "svg/illustrations/LogoIllustrationSVG";
import { useTranslation } from "react-i18next";
import { Loader } from "UI/Loader/Loader";
import styles from "./MainPage.module.scss";
import Hash from "components/Hash/Hash";
import { useNavigate } from "react-router-dom";
import FoundPaymentIllustrationSVG from "svg/illustrations/FoundPaymentIllustrationSVG";
import useTokenStore from "store/TokenStore";
import { usePaymentData } from "hooks/usePaymentData";
import useDeviceStore from "store/DeviceStore";
import { getDeviceInfo } from "common/commonFunctions";
import useErrorStore from "store/errorStore";

const MainPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getPaymentData, paymentData} = usePaymentData();
  const { setToken} = useTokenStore();
  const [localToken,setLocalToken] = useState('')
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState(<LogoIllustrationSVG height="200" width="200" />);
  const [loadingText, setLoadingText] = useState(t("Загружаем страницу платежа"));
  const [urlParams,setUrlParams] = useState('')
  const { setDevice } = useDeviceStore();
  const { networkErr } = useErrorStore((state) => state);

  useEffect(() => {
    const url = window.location.href;
    const paramsString = url.substring(url.lastIndexOf("/") + 1);
    setUrlParams(paramsString);    
    sessionStorage.setItem('p_token', paramsString);
    getPaymentData(paramsString);
    getDeviceInfo(setDevice);
    
  }, []);

  useEffect(() => {
    setLocalToken(urlParams);
    setToken(urlParams)

    if(paymentData?.status_name === 'CANCELED'){
      navigate('/canceled',{state: 'CANCELED'})
    }

    if(paymentData?.bic === null && paymentData.account_number !== null){
      navigate('/canceled')
    }
    
  }, [setLocalToken, localToken, paymentData, setToken, urlParams]);

  useEffect(() => {
    setLoading(true);
    const firstTimerId = setTimeout(() => {
      setLoadingText(t("Ищем подходящий реквизит"));
      setImg(<FoundPaymentIllustrationSVG height="200" width="200" />);
    }, 2000);

    const secondTimerId = setTimeout(() => {
      if(networkErr){
        navigate('/canceled', {state: 'NETWORKERR'});
      }else{
        if(paymentData?.bank_name === 'Сбербанк' && paymentData.transfer_method !== 'ACCOUNT_NUMBER'){
          navigate("/method");
        }else{
          navigate("/payment");
        }
      }
    }, 5000);

    return () => {
      setLoading(false);
      clearTimeout(firstTimerId);
      clearTimeout(secondTimerId);
    };
  }, [navigate, t, networkErr,paymentData]);

  return (
    <Layout headerText={t("Загрузка и поиск реквизитов")} currentStep={0} loading={loading}>
      <div className={styles.up}>
        <Hash />
        <Loader />
      </div>
      <div className={styles.content}>
        {img}
        <div className={styles.title}>{loadingText}</div>
      </div>
    </Layout>
  );
};

export default MainPage;